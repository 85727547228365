import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "../../pages/private/dashboard/workspace/Loader/loaders";
import { loginEvent } from "lib/gtmEvents";
import axios from "axios";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
export const PwImageRedirect = () => {
  const history = useHistory();

  const loadPwUser = async (token: string, imageUrl: string) => {
    await axios
      .post(`${process.env.REACT_APP_API_REDIRECT}/pw-guestlogin`, {
        token: token,
      })
      .then((res: any) => {
        if (res.status === 200) {
          let user = res.data;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("email", JSON.stringify(user.email));
          localStorage.setItem("token", user.id);
          localStorage.setItem("userId", user.userId);
          let obj: any = { method: "email" };
          loginEvent(obj);
          sessionStorage.setItem("url", imageUrl);
          window.location.href = "/dashboard/workspace";
        }
      })
      .catch((e) => {
        toast.error("Ooops... there was an error, please try again");
      });
  };
  useEffect(() => {
    if (isMobile) {
      window.location.href = "/mobile-registration";
    } else {
      var urlParams: any, token: any, imageUrl: any;
      urlParams = new URLSearchParams(window.location.search);
      token = urlParams.get("token");
      imageUrl = urlParams.get("imageUrl") || "";
      if (token && imageUrl) {
        loadPwUser(token, imageUrl);
      } else {
        history.push("/signup");
      }
    }
  }, []);

  return (
    <div>
      <Loader textShow={true} />
    </div>
  );
};

export default PwImageRedirect;
