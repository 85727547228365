import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Loader } from "../../../../pages/private/dashboard/workspace/Loader/loaders";
import { useDispatch } from "react-redux";
import { getTemplateData } from "new-dw-ui/src/lib/api/workspace";
import { isMobile } from "react-device-detect";
export const Template = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const checkUser = () => {
    const user: any = localStorage.getItem("user");
    if (user) return true;
    else return false;
  };

  const getCompositionData = async (tempId: any) => {
    await getTemplateData(tempId)
      .then((res) => {
        const categoryId = res?.data?.categoryId;
        dispatch({
          type: "setNewResizeId",
          newResizeTitleId: categoryId,
        });
        if (checkUser()) {
          if (localStorage.getItem("prevDashboardSearchParam")) {
            localStorage.setItem("redirectToDashboardTemps", "true");
          }
          history.push("/dashboard/workspace");
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        if (checkUser()) {
          history.push("/dashboard/workspace");
        } else {
          history.push("/");
        }
        dispatch({
          type: "isCanvasLoad",
          isCanvasLoadData: {
            type: "tempData",
            status: false,
          },
        });
      });
  };
  useEffect(() => {
    if (isMobile) {
      window.location.href = "/mobile-registration";
    } else {
      if (
        localStorage.getItem("prevDashboardSearchParam") &&
        localStorage.getItem("redirectToDashboardTemps")
      ) {
        const prevSearch: any = localStorage.getItem(
          "prevDashboardSearchParam"
        );
        localStorage.removeItem("prevDashboardSearchParam");
        localStorage.removeItem("redirectToDashboardTemps");
        return history.push(prevSearch);
      }
      var tempId = decodeURIComponent(window.location.href).split(
        "template/"
      )[1];
      sessionStorage.setItem("tempId", JSON.stringify(tempId));
      getCompositionData(tempId);
    }
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Template;
